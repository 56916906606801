.CP-container {
    width: 60%;
    margin: auto;
    text-align: center;
}

.CP-heading {
    margin-bottom: 1.5rem;
}

.CP-subheading {
    margin-bottom: 0.5rem;
}

.CP-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 60%;
    margin: auto;
    text-align: center;
}

.CP-info-heading {
    font-weight: bold;
    margin-bottom: .35rem;
}

.CP-info p {
    flex: 1;
    margin: 1rem 0;
    min-width: 250px; 
}

.CP-info span {
    text-decoration: underline;
}

.CP-icon {
    margin-right: 0.5rem; /* Fügt Abstand zwischen Icon und Text hinzu */
    color: #333; /* Setzt die Farbe der Icons */
    font-size: 1.2rem; /* Setzt die Größe der Icons */
}

.rotate-icon {
    display: inline-block;
    transform: rotate(90deg); /* Dreht das Icon um 90 Grad */
}



/* Media Query für Mobilgeräte */
@media (max-width: 920px) {
    .CP-container {
        width: 90%; /* Verkleinert den Container auf kleineren Bildschirmen */
    }

    .CP-info {
        flex-direction: column; /* Stapelt die Absätze untereinander */
        align-items: center;
    }

    .CP-info p {
        text-align: center;
        margin: 0.5rem 0;
    }
}
