/* Container für die PP-Seite */
.PP-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px; /* Abgerundete Ecken */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Schatteneffekt */
}

/* Titel der PP */
.PP-title {
    font-size: 2rem; /* Einheitliche Schriftgröße */
    margin-bottom: 20px; /* Abstand unten */
    text-align: center; /* Titel zentriert */
    color: #333; /* Textfarbe */
}

/* Inhalt der PP */
.PP-content {
    line-height: 1.6; /* Zeilenhöhe für bessere Lesbarkeit */
}

/* Untertitel für die Abschnitte der PP */
.PP-section-title {
    font-size: 1.5rem; /* Einheitliche Schriftgröße */
    margin-top: 20px; /* Abstand oben */
    margin-bottom: 10px; /* Abstand unten */
    color: #0056b3; /* Einheitliche Farbe (blau) */
}

/* Textabschnitt in der PP */
.PP-paragraph {
    margin-bottom: 15px; /* Abstand unten */
    color: #555; /* Textfarbe für den Hauptinhalt */
}

/* Kontaktinfo (falls vorhanden) */
.PP-contact-info {
    margin-top: 20px; /* Abstand oben */
    font-size: 1rem;
    color: #333;
    font-weight: bold; /* Fettschrift für Kontaktinfo */
}

/* Stile für Links in der PP */
.PP-container a {
    color: #0056b3; /* Blaue Links */
    text-decoration: none; /* Keine Unterstreichung */
}

.PP-container a:hover {
    text-decoration: underline; /* Unterstreichung beim Hover */
}
