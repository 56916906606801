/* General styling adjustments for mobile responsiveness */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.SA-container {
    display: flex;
    flex-direction: row; /* Two-column layout for larger screens */
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.SA-image-container {
    width: 50%; /* 50% width for desktop view */
    max-height: 500px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.SA-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the entire container */
}

/* For cases with only one image */
.slick-slider .slick-track,
.slick-list {
    display: flex !important;
    justify-content: center; /* Center a single image */
}

.slick-dots {
    display: none; /* Hide dots if there's only one image */
}

/* Apartment details styling */
.SA-details {
    width: 50%;
    margin-left: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.SA-details h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.SA-details p {
    margin: .5rem 0 1rem 0;
    color: #666;
}

.SA-booking {
    margin-top: 20px;
}

.SA-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.SA-date-picker,
.SA-nights-dropdown,
.SA-form-label input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0.5rem 0;
}

.SA-cho-context {
    margin-bottom: .75rem;
    font-size: .9rem;
}

.SA-pflichtfeld {
    font-size: .75rem;
    color: #6f6f6f;
    margin-bottom: 5rem;
}

.SA-optional {
    font-size: .85rem;
    color: #6f6f6f;
}

/* Button styles */
.SA-book-now-btn {
    padding: 10px 15px;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.SA-book-now-btn:hover {
    background-color: #005bb5;
}

/* Disabled button styling */
.SA-book-now-btn:disabled {
    background-color: #6f6f6f;
    cursor: not-allowed;
    opacity: 0.5;
}

/* Modal Styling */
.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%; /* Adjusted for responsiveness */
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    color: #aaa;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .SA-container {
        flex-direction: column; /* Stack columns for mobile */
    }

    .SA-image-container {
        width: 100%; /* Full width for images */
    }

    .SA-details {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .modal-content {
        width: 100%;
        max-width: 400px;
    }
}
