/* Main container for the login page */
.login-container {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Full viewport height */
    background-color: #f4f4f4; /* Light grey background */
    padding: 20px; /* Padding around the container */
}

/* Heading styles */
.heading {
    font-size: 2rem; /* Large font size */
    color: #333; /* Darker text color */
    margin-bottom: 20px; /* Space below heading */
}

/* Form styles */
form {
    background: white; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 40px; /* Inner padding */
    width: 100%; /* Full width on smaller screens */
    max-width: 400px; /* Max width for the form */
}

/* Input container */
.input-container {
    display: flex;
    flex-direction: column; /* Align inputs vertically */
}

/* Input styles */
input[type="text"],
input[type="password"] {
padding: 15px; /* Inner padding */
    margin-bottom: 20px; /* Space below inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    transition: border 0.3s; /* Smooth border transition */
}

/* Change border color on focus */
input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #007bff; /* Primary color on focus */
    outline: none; /* Remove default outline */
}

/* Button styles */
button {
    background-color: #007bff; /* Primary color for button */
    color: white; /* White text */
    padding: 15px; /* Inner padding */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s; /* Smooth background transition */
}

/* Change button color on hover */
button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Responsive styles */
@media (max-width: 400px) {
    .login-container {
        padding: 10px; /* Reduce padding on small screens */
    }

    form {
        padding: 20px; /* Reduce inner padding on small screens */
        width: 90%; /* Full width on small screens */
    }
}
