/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f7fb;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Dashboard Container */
.AD-dashboard-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
}

/* Header Styling */
.AD-dashboard-header {
    text-align: left;
    margin-bottom: 40px;
}

.AD-dashboard-header h1 {
    font-size: 36px;
    color: #333;
    margin: 0;
}

/* Apartment List (Grid Layout) */
.AD-apartment-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

/* Apartment Item Styling (Card Layout) */
.AD-apartment-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.AD-apartment-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

/* Title and Description */
.AD-apartment-item h3 {
    font-size: 24px;
    color: #333;
    margin: 0 0 10px 0;
}

.AD-apartment-item p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

/* Price and Availability */
.AD-price {
    font-size: 1rem;
}

.AD-available {
    color: #28a745;
    font-weight: bold;
}

.AD-unavailable {
    color: #dc3545;
    font-weight: bold;
}

/* Buttons Styling */
.AD-apartment-item button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
}

.AD-apartment-item button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.AD-apartment-item .AD-edit-btn {
    background-color: #007bff;
}

.AD-apartment-item .AD-edit-btn:hover {
    background-color: #0056b3;
}

.AD-apartment-item .AD-delete-btn {
    background-color: #f44336;
}

.AD-apartment-item .AD-delete-btn:hover {
    background-color: #e53935;
}

/* Modal Overlay and Content */
.AD-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.AD-modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Modal Close Button */
.AD-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
}

.AD-close-button:hover {
    color: #dc3545;
}

/* Form Fields */
form input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
}

form input:focus {
    outline: none;
    border-color: #007bff;
}

/* Radio Buttons Styling */
form input[type="radio"] {
    margin-right: 10px;
    display: inline-block;
    margin-left: 0;
}

form label {
    font-size: 16px;
    margin-right: 15px;
    display: inline-block;
}

/* Modal Buttons */
.AD-modal-content form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.AD-confirm-btn,
.AD-cancel-btn {
    display: inline-block;
    width: 48%;
    padding: 12px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.AD-confirm-btn {
    background-color: #007bff;
    color: white;
}

.AD-confirm-btn:hover {
    background-color: #0056b3;
}

.AD-cancel-btn {
    background-color: #f44336;
    color: white;
}

.AD-cancel-btn:hover {
    background-color: #e53935;
}

/* Responsive Design */
@media (max-width: 768px) {
    .AD-dashboard-container {
        padding: 20px;
    }

    .AD-apartment-list {
        grid-template-columns: 1fr;
    }

    .AD-modal-content {
        width: 100%;
        padding: 20px;
    }

    .AD-close-button {
        top: 10px;
        right: 10px;
        font-size: 18px;
    }

    .AD-confirm-btn,
    .AD-cancel-btn {
        width: 100%;
        margin-top: 10px;
    }
}
