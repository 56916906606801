/* Assuming your footer CSS file is src/styles/Footer.css */

.footer {
    background-color: #333; /* Background color for the footer */
    color: #fff; /* Text color */
    padding: 20px; /* Padding around the footer */
    text-align: center; /* Center align text */
    position: relative; /* Set position to relative */
    width: 100%; /* Full width */
    bottom: 0; /* Align to bottom */
    margin-top: auto; /* Push the footer to the bottom of the page */
}

/* This will keep the footer always visible on smaller screens */
@media (max-width: 768px) {
    .footer {
        position: static; /* Keep footer in the document flow */
    }
}

.footer-nav ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.footer-nav li {
    display: inline; /* Display list items inline */
    margin: 0 15px; /* Margin between items */
}

.footer-nav a {
    color: #fff; /* Link color */
    text-decoration: none; /* Remove underline */
}

.footer-nav a:hover {
    text-decoration: underline; /* Underline on hover */
}

.footer-copyright {
    margin-top: 10px; /* Space above copyright text */
}
