.AGB-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.AGB-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.AGB-section-title {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.AGB-paragraph {
    margin-bottom: 15px;
}
