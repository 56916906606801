/* Main title of the apartments page */
.page-title {
    text-align: center; /* Center the title */
    margin: 20px 0; /* Space around the title */
    font-size: 2rem; /* Slightly smaller font size for the title */
    color: #333; /* Dark text color */
}

/* Container for the grid of apartments */
.apartments-grid {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
    gap: 15px; /* Space between grid items */
    padding: 20px; /* Inner padding */
    margin: 0 10%; /* Margin from the sides */
}

/* Style for each apartment card */
.apartment-card {
    background: white; /* White background */
    border-radius: 8px; /* Slightly reduced border radius for less roundness */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Lighter shadow */
    overflow: hidden; /* Hide overflow */
    transition: transform 0.3s; /* Smooth transform effect */
}

/* Scale up the card on hover */
.apartment-card:hover {
    transform: scale(1.03); /* Slightly smaller scale effect */
}

/* Image styling */
.apartment-image {
    width: 100%; /* Full width of the card */
    height: 200px; /* Decreased height for the image */
    object-fit: cover; /* Cover the area without stretching */
}

/* Information section of the apartment card */
.apartment-info {
    padding: 10px; /* Reduced inner padding */
}

/* Title styling */
.apartment-title {
    font-size: 1.4rem; /* Slightly smaller font size for the title */
    color: #1b1b1b; /* Primary color */
    margin: 0; /* Remove default margin */
}

/* Price styling */
.apartment-price {
    font-size: 1rem; /* Slightly smaller font size for the price */
    color: #444; /* Darker grey */
    margin-top: 5px;
}

/* Location styling */
.apartment-location {
    font-size: .85rem; /* Normal font size for location */
    color: #666; /* Lighter grey for location */
    margin: .5rem 0;
}

/* Availability styling */
.apartment-availability {
    font-size: 1rem; /* Normal font size for availability */
    color: #28a745; /* Green color for availability */
    margin-top: 5px;
}

.apartment-availability.not-available {
    color: #dc3545; /* Red color if not available */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .apartments-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .apartments-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
}

@media (min-width: 1024px) {
    .apartments-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for large screens */
    }
}

/* Button styling */
.filter-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-btn:hover {
    background-color: #0056b3;
}

/* Modal overlay styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content styling */
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%; /* Prevents overflow on small screens */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Ensure that overflow is hidden */
}

/* Modal header styling */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-modal {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}

/* Other existing styles remain unchanged */
.filter-section,
.sort-section {
    margin-bottom: 15px;
}

.filter-section label,
.sort-section label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

/* Adjust input styles */
.filter-section select,
.sort-section select {
    width: calc(100% - 16px); /* Full width minus padding */
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-top: 5px; /* Space between label and input */
    background: none;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* Add space above buttons */
}

.modal-buttons button {
    background-color: #007bff;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: #0056b3;
}
