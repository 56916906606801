/* Modal Overlay */
.APM-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
    opacity: 1;
}

/* Modal Container */
.APM-modal-content {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 25px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.4s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align form content */
}

/* Modal Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Modal Header */
.APM-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Full width for header alignment */
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

/* Modal Title */
.APM-modal-title {
    font-size: 1.6em;
    color: #333;
    font-weight: bold;
}

/* Close Button */
.APM-close-button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #333;
    transition: color 0.2s ease;
}

.APM-close-button:hover {
    color: #555;
}

/* Form Fields */
.APM-input-field {
    margin-bottom: 20px;
    width: 100%; /* Ensure full width for each field */
}

.APM-input-field label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #555;
}

.APM-input-field input[type="text"],
.APM-input-field input[type="number"],
.APM-input-field input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fafafa;
    transition: border-color 0.3s ease;
}

.APM-input-field input[type="text"]:focus,
.APM-input-field input[type="number"]:focus,
.APM-input-field input[type="file"]:focus {
    border-color: #007bff;
    outline: none;
}

/* Radio Button Options */
.APM-input-field input[type="radio"] {
    margin-right: 8px;
}

.APM-radio-container {
    display: flex;
    gap: 1rem;
}

.availability-radio-label {
    padding-left: 6px;
    display: inline-flex;
    gap: .15rem;
}

/* Save and Cancel Buttons */
.APM-button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    width: 100%; /* Full width for alignment */
}

.APM-button-container button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
}

.APM-save-button {
    background-color: #007bff;
    color: #fff;
}

.APM-save-button:hover {
    background-color: #0069d9;
}

.APM-cancel-button {
    background-color: #dc3545;
    color: #fff;
}

.APM-cancel-button:hover {
    background-color: #c82333;
}

.APM-button-container button:active {
    transform: scale(0.98);
}

/* Form Buttons Styling */
button {
    transition: transform 0.2s ease;
}

button:hover {
    transform: translateY(-2px);
}

button:active {
    transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 500px) {
    .APM-modal-content {
        width: 90%;
        padding: 15px;
    }

    .APM-modal-title {
        font-size: 1.4em;
    }

    .APM-button-container button {
        font-size: 0.9em;
        padding: 10px;
    }
}
