html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;  /* Verhindert horizontalen Scroll */
    width: 100%;
}

/* Container */
.HP-homepage-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; /* Damit bei kleinen Bildschirmgrößen die Container nebeneinander angeordnet werden */
}

/* Verfügbare Apartments */
.HP-available-apartments {
    flex: 2;
}

.HP-apartments-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;
}

/* Nicht verfügbare Apartments */
.HP-unavailable-apartments {
    flex: 1;
    max-width: 300px;
    margin-left: 2rem;
    padding: 1rem;
}

.HP-apartments-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Apartment Cards */
.HP-apartment-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 100%;
}

.HP-apartment-card:hover {
    transform: scale(1.05);
}

.HP-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.HP-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Große vs. kleine Cards */
.HP-apartment-card.HP-large {
    flex: 0 1 48%; /* Zwei nebeneinander */
}

.HP-apartment-card.HP-small {
    width: 100%;
    height: auto;
    min-height: 250px;
}

/* Apartment Info */
.HP-apartment-info {
    padding: 1rem;
}

.HP-apartment-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.HP-apartment-location {
    font-size: 1rem;
    color: #555;
}

.HP-apartment-price {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.HP-availability-status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: 0.5rem;
}

.HP-availability-status.HP-available {
    background-color: #4caf50;
}

.HP-availability-status.HP-unavailable {
    background-color: #f44336;
}

/* Action Buttons */
.HP-action-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

button {
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

.HP-delete-btn {
    background-color: #f44336;
}

.HP-delete-btn:hover {
    background-color: #e53935;
}

/* Media Queries für mobile Geräte */
@media (max-width: 768px) {
    .HP-homepage-container {
        flex-direction: column;
        padding: 1rem;
    }

    .HP-available-apartments,
    .HP-unavailable-apartments {
        flex: none;
        max-width: 100%;
        margin-left: 0;
    }

    .HP-apartments-flex {
        justify-content: center;
    }

    .HP-apartment-card.HP-large {
        flex: 0 1 100%;
    }
}
