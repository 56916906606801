.OC-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.OC-title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
}

.OC-title-h3 {
        font-weight: 600;
        color: #333;
        margin-bottom: 20px;

}

.OC-details {
    width: 100%;
}

.OC-item {
    font-size: 18px;
    color: #555;
    margin: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

.OC-item:last-child {
    border-bottom: none;
}

strong {
    color: #333;
}
