.carousel-container {
    width: 100%;
}

.carousel-slide {
    display: flex;
    justify-content: center;
}

.carousel-image {
    width: 100%;  /* Ensure the image fills the space */
    height: auto; /* Maintain aspect ratio */
}
