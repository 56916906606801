/* Main Heading at the top */
.NB-heading {
    font-size: 2rem; /* Default size for desktop */
    font-weight: bold;
    color: #333;
    margin: 2rem 0 1rem 0;
    text-align: center; /* Centered heading on desktop */
}




/* Navigation Menu */
.NB-navbar {
    display: flex;
    justify-content: center; /* Centering nav items */
    align-items: center;
    list-style: none;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.NB-navbar li {
    margin: 0;
}

.NB-navbar a,
.NB-navbar button {
    color: #333;
    text-decoration: none;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.NB-navbar button {
    padding: 0.5rem 1rem;
}

/* Burger Menu Button */
.NB-burger {
    background-color: #333;
    display: none; /* Hidden on desktop */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001; /* Ensure it stays above backdrop */
}

.NB-burger-bar {
    width: 100%;
    height: 3px;
    background-color: red; /* Color for burger bars */
    transition: all 0.3s ease;
}

/* .NB-burger.active {
    background: red;
} */

/* Transform burger to "X" */
.NB-burger.active .NB-burger-bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.NB-burger.active .NB-burger-bar:nth-child(2) {
    opacity: 0; /* Hide the middle bar when active */
}

.NB-burger.active .NB-burger-bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
    /* Show the burger menu on mobile */
    .NB-burger {
        display: block; /* Show burger menu on mobile */
    }

    /* Adjust the logo font size on mobile */
    .NB-heading {
        font-size: 1.5rem; /* Smaller font size for mobile */
        text-align: left; /* Left-aligned heading on mobile */
        width: 100%; /* Ensures heading takes the full width */
        margin-left: 10px; /* Slight margin to the left */
    }

    /* The actual nav menu */
    .NB-navbar {
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 75%; /* 75% width for the menu */
        background-color: #f2f2f2; /* Background color */
        transform: translateX(100%); /* Initially hidden */
        transition: transform 0.3s ease;
        justify-content: center;
        z-index: 1000;
    }

    /* Show the nav menu when burger is clicked */
    .NB-nav.open .NB-navbar {
        transform: translateX(0);
    }

    /* Backdrop to close the menu when clicked */
    .NB-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%; /* Cover the entire viewport */
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
        z-index: 999; /* Below the burger menu but above content */
    }
}

/* Admin context styles */
.NB-admin-context {
    color: #444;
    font-size: 0.9rem;
    text-align: center;
}
